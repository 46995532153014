import * as React from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex'
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover'
import { Container } from './Container'
import AnimateHeight from 'react-animate-height'
import Link from 'next/link'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons'

type Props = {
  title: string
  description: string
}
export const FAQSection: React.FC<Props> = ({ title, description }) => {
  return (
    <section className="text-center">
      {/*language=SCSS*/}
      <style jsx>{`
        @media (max-width: 768px) {
          .title {
            font-size: 2em;
            color: #000000;
            font-weight: 700;
          }
        }

        .title {
          font-size: 40px;
          color: #000000;
          font-weight: 700;
        }

        .description {
          font-size: 18px;
          color: #7c7c7c;
          font-weight: 400;
        }
      `}</style>
      <Container>
        <div>
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FAQ question={`What does your quote include?`}>
            <p>
              We pride ourselves on a fully transparent quote and repair process. Each quote includes parts, labor, and
              taxes. On average, our prices are 15-40% lower than your standard dealership or auto repair shop.
            </p>
            <p>
              After the quote over the phone, our mechanics will then do an official inspection with you in person for
              recommended repairs.
            </p>
          </FAQ>
          <FAQ question={`How does the diagnosis work?`}>
            <p>
              We will give you a preliminary diagnosis over the phone. Using that diagnosis as a guide, a trained
              mechanic will then do an inspection with you in person to determine final recommended repairs.
            </p>
            <p>
              If the in-person diagnosis reveals that you do not need any repairs at this time, we will charge a $79
              diagnostic fee that can be used as credit for a future repair.
            </p>
            <p>
              If you decline all recommended repairs during the in-person diagnosis, we will charge a $79 service call
              fee, which cannot be used as credit, to pay for the costs of the in-person visit.
            </p>
          </FAQ>
          <FAQ question={`Who handles the repairs?`}>
            <p>
              All of our technicians have extensive brake repair knowledge with years of experience and are directly
              employed by NuBrakes, so you know you’re in good hands.
            </p>
          </FAQ>
          <FAQ question={`What type of parts do you use?`}>
            <p>
              We only use premium brake parts that are built to match the OEM (Original Manufacturer Equipment)
              standards to ensure you’re getting the best braking performance possible.
            </p>
          </FAQ>
          <FAQ question={`Do you offer a warranty`}>
            <p>
              We do! All of our work is backed by our <Link href={'/warranty'}>NuBrakes Guarantee</Link> to ensure your
              brakes perform as they should each and every day.
            </p>
          </FAQ>
          <FAQ question={`Do you do any other auto repairs?`}>
            <p>
              Our primary focus is brake repairs and brake service. The best part is we can’t upsell you on unnecessary
              repairs like traditional repair shops because we don’t even offer them!
            </p>
          </FAQ>
          <FAQ question={`How do I pay for my repairs?`}>
            <div className="px-3">
              <p className="answer">
                After the service is completed, our technicians are able to take payment on-site through all major
                credit and debit cards. We do not accept cash as a payment method.
              </p>
              <div>
                <FontAwesomeIcon icon={faCcVisa} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcMastercard} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcAmex} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcDiscover} className={`mx-1`} size={'2x'} />
              </div>
            </div>
          </FAQ>
        </div>
      </Container>
    </section>
  )
}

export const FAQ: React.FC<{ question: string; children?: any }> = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const height = isOpen ? 'auto' : 0
  return (
    <div className={'flex flex-col flex-grow'}>
      <div className="text-left rounded border border-gray-200">
        <div>
          <div className="px-4 py-3 bg-gray-200 h-100" style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
            <div className="flex items-center">
              <div className="flex pr-4">
                <FontAwesomeIcon icon={faPlus} className={`mx-1`} size={'1x'} />
              </div>
              <p className="question mb-0">
                <strong>{question}</strong>
              </p>
            </div>
          </div>
          <AnimateHeight duration={350} height={height}>
            <div className="bg-white py-4">
              <div>
                <div className="answer px-3">{children}</div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  )
}
